import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Modal from '../components/Modal'
// import SampleForm from '../components/Form'
// import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

// Images
// import quality from '../img/quality.svg'
// import honesty from '../img/honesty.svg'
// import expertise from '../img/expertise.svg'
// import logoIcon from '../img/logo-icon.svg'
import logoIconWhite from '../img/logo-icon-white.svg'
// import pp1 from '../img/pp1.jpg'
// import pp2 from '../img/pp2.jpeg'
// import pp3 from '../img/pp3.jpeg'
// import team1 from '../img/team1.jpeg'
// import team2 from '../img/team2.jpeg'
// import team3 from '../img/team3.jpeg'
// import team4 from '../img/team4.jpeg'
// import fb from '../img/facebook.svg'
// import twitter from '../img/twitter.svg'
// import linkedin from '../img/linkedin.svg'
// import logoWhite from '../img/logo-white.svg'
// import kiplinger from '../img/kiplinger.png'
// import wired from '../img/wired.png'
// import wsj from '../img/wsj.png'

import '../components/faq.css'

class FaqPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="FAQ" description={siteDescription} />

        <div className="container-fluid headline">
          <div className="container">
            <div className="col-md-10 offset-md-1">
              
              <h1 className="text-white mt-0">
                <img src={logoIconWhite} className="logo-icon" alt="Logo" />
                Frequently Asked Questions
              </h1>
            </div>
          </div>
        </div>

        <div className="container faq-content">
          <div className="row px-4">
            <div className="col-md-8 offset-md-2">
              <span className="tech-num-1"></span>
              <h4>Will this work for my application?</h4>
              <hr />
              <p>
                Our Technology has been used in a wide-range of agricultural
                applications, including row crops and vegetable seeds, as well
                as pharmaceutical, textile and food quality applications. Check
                out our seed application or feed application pages, or click
                here to schedule a time to talk to one of our application
                specialists about your unique needs.
              </p>
            </div>

            <div className="col-md-8 offset-md-2">
              <span className="tech-num-2"></span>
              <h4>
                This is pretty complex technology. How, exactly, does it work?
              </h4>
              <hr />
              <p>
                While multispectral imaging has been used in labs for years,
                combining it with machine learning is a more recent innovation. <a
                  href="https://calendly.com/skywayanalytics/skyway-analytics-learn-more-website-faq-clone"
                  target="_blank" rel="noopener noreferrer"
                >Get in touch</a> with one of our technology specialists to ask questions and
                learn more about multispectral imaging and machine learning.
              </p>
            </div>
            <div className="col-md-8 offset-md-2">
              <span className="tech-num-3"></span>
              <h4>
                I’m interested but not convinced. How can I be sure about this
                technology?
              </h4>
              <hr />
              <p>
                You can <a
                  href="https://calendly.com/skywayanalytics/skyway-analytics-learn-more-website-faq-clone"
                  target="_blank" rel="noopener noreferrer"
                >connect with one of our application specialists
                </a> to dive into the next level of detail and get answers to your
                tough questions. You can also <a
                  href="https://calendly.com/skywayanalytics/skyway-analytics-feasability-website-faq"
                  target="_blank" rel="noopener noreferrer"
                >fill out this form</a> to request a feasibility test for your unique application.”
              </p>
            </div>
            <div className="col-md-8 offset-md-2 mt-5">
               <Modal bsPrefix="btn-global mr-5 nav-cta mb-3" html="Get a Quote">
                <iframe
                  className="border-0"
                  title="calendaly"
                  src="https://calendly.com/skywayanalytics/skyway-analytics-learn-more-website-faq-clone?month=2019-11"
                  height="700px"
                  width="100%"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                />
            </Modal>
            <Modal bsPrefix="btn-global mr-5 nav-cta mb-3" html="Start a Feasibility Test">
                <iframe
                  className="border-0"
                  title="calendaly"
                  src="https://calendly.com/skywayanalytics/skyway-analytics-feasability-website-faq"
                  height="700px"
                  width="100%"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                />
            </Modal>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default FaqPage

export const FaqPageQuery = graphql`
  query FaqPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
